.glide {
  position: relative;
  display: flex;
  align-items: center;
}

.glide__track {
  flex: 1;
  /* width: 80%; */
}

.glide__arrows {
  position: relative;
  padding: 5px;
  background: transparent;
  box-shadow: none;
}

.glide__arrow {
  position: relative;
  top: 0;
  margin: 0;
  transform: none;
  left: 0;
  right: 0;
  padding: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  text-shadow: none;
  transition: all 0.2s ease-in-out;
}

.glide__arrow img {
  height: 22px;
  width: auto;
  opacity: .5;
  transition: all .3s ease;
}

.glide__arrow img:hover {
  opacity: 1;
}

.glide__arrow.glide__arrow--left img {
  width: auto;
  transform-origin: center;
  transform: scale(-1);
}

.glide__arrow:hover {
  /* background-color: #ed145b; */
}

.controller {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controller .glide__bullets {
  position: relative;
  left: 0;
  bottom: initial;
  transform: translateX(0);
}

.controller .glide__bullets .glide__bullet{
  margin: 0 16px;
}